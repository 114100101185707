<template>
  <a-card :bodyStyle="{padding:0}">
    <template #title>
      <a-button @click="exportDetailExcel">导出明细</a-button>
    </template>
    <a-table :dataSource="dataSource" :rowKey="record => record.orderId" :loading="loading" :pagination="false" bordered size="small" :scroll="{x: true}" :rowClassName="rowClass">
      <a-table-column width="50" title="订单号" data-index="orderId" />
      <a-table-column width="50" title="业务员" data-index="salesman" />
      <a-table-column width="50" title="部门" data-index="orgName" />
      <a-table-column width="100" title="起点" data-index="startAddress" />
      <a-table-column width="100" title="终点" data-index="endAddress" />
      <a-table-column width="100" title="入单时间" data-index="orderTime" />
      <a-table-column width="50" title="未结算金额" data-index="settableAmt" />
      <a-table-column width="50" title="应回款时间" data-index="collectionTime" />
      <a-table-column width="50" title="超期天数" data-index="overdueDay" />
      <a-table-column width="100" title="客户名称" data-index="customerName" />
      <a-table-column width="100" title="责任人" data-index="creditor" />
      <a-table-column width="100" title="账期" data-index="deadline" />
      <a-table-column width="100" title="账期备注" data-index="accountPeriodRemark" />
      <template #footer></template>
    </a-table>
  </a-card>
</template>
<script>
import { accountAgeEmpList, exportProfitArchive, exportProfitDetailArchive } from '@/api/trans/finance/accountAge'
import { reactive, toRefs } from 'vue'
import { watch } from '@vue/runtime-core'
import { message } from 'ant-design-vue'
export default {
  props: { query: { type: Object, default: () => { return { year: '', month: '', employeeId: '' } } } },
  setup (props) {
    const state = reactive({
      loading: false,
      orgs: [],
      dataSource: []
    })

    watch(() => props.query, (query) => {
      state.dataSource = []
      accountAgeEmpList(query).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data
          state.orgs = res.data.map(val => { return { text: val.orgName, value: val.orgId } })
          state.dataSource.push(tableTotal(res.data))
        }
      })
    })

    const tableTotal = (data = []) => {
      const totalRow = {
        settableAmt: 0
      }
      data.forEach(item => {
        for (const key in totalRow) {
          totalRow[key] += Number(item[key])
        }
      })
      totalRow.orderId = '合计'
      return totalRow
    }

    // const tableChage = (pagination, filters) => {
    //   const filterData = state.dataSource.filter(item => filters.orgName.includes(item.orgId))
    //   if (filterData.length) {
    //     state.dataSource.splice(state.dataSource.length - 1, 1, tableTotal(filterData))
    //   } else {
    //     state.dataSource.splice(state.dataSource.length - 1, 1, tableTotal(state.dataSource.slice(0, -1)))
    //   }
    // }

    const exportExcel = () => {
      exportProfitArchive(props.query, '利润报表').then(res => {
        if (res === 10000) {
          message('导出成功！')
        }
      })
    }

    const exportDetailExcel = () => {
      exportProfitDetailArchive(props.query, '利润报表').then(res => {
        if (res === 10000) {
          message('导出成功！')
        }
      })
    }

    const rowClass = (record, index) => {
      let rowClassName = ''
      if (index % 2 === 1) {
        rowClassName = 'table-striped'
      }
      if (record.status && !record.status.value) {
        rowClassName += ' not-archive'
      }
      return rowClassName
    }

    return {
      ...toRefs(state),
      rowClass,
      exportExcel,
      exportDetailExcel
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
