import { postBodyRequest, getRequest, exportRequest } from '@/utils/axios'

export function orgProfit (params) {
  return postBodyRequest('/trans/finance/profit/org', params)
}

export function saleEmpProfit (id, params) {
  return postBodyRequest(`/trans/finance/profit/${id}/emp`, params)
}

export function saleEmpProfitDetail (empId, params) {
  return postBodyRequest(`/trans/finance/profit/emp/${empId}`, params)
}

export function transProfit (params) {
  return postBodyRequest('/trans/finance/profit/trans', params)
}

export function transProfitUpdate (params) {
  return postBodyRequest('/trans/finance/profit/archive/update', params)
}

export function accountAgeTree (params) {
  return postBodyRequest('/trans/finance/accountAge/tree', params)
}

export function accountAgeOrgList (params) {
  return postBodyRequest('/trans/finance/accountAge/list', params)
}

export function accountAgeEmpList (params) {
  return postBodyRequest('/trans/finance/accountAge/emp/list', params)
}

export function transProfitArchiveDetail (archiveId, params) {
  return getRequest(`/trans/finance/profit/archive/${archiveId}`, params)
}

export function updateAccountAge (params) {
  return postBodyRequest('/trans/finance/accountAge/update', params)
}

export function exportProfitArchive (params, fileName) {
  return exportRequest('/trans/finance/profit/archive/export', params, fileName)
}

export function exportProfitDetailArchive (params, fileName) {
  return exportRequest('/trans/finance/profit/archive/detail/export', params, fileName)
}

export function exportAccountAgeDetail (params, fileName) {
  return exportRequest('/trans/finance/accountAge/export', params, fileName)
}

export function saveProfitArchive (archiveId, params) {
  return postBodyRequest(`/trans/finance/profit/archive/${archiveId}/save`, params)
}

export function archiveProfitArchive (archiveId, params) {
  return postBodyRequest(`/trans/finance/profit/archive/${archiveId}/archive`, params)
}

export function saleProfitTree (params) {
  return postBodyRequest('/trans/finance/profit/archive/sale/tree', params)
}

export function saleProfitList (params) {
  return postBodyRequest('/trans/finance/profit/archive/sale/profitList', params)
}
