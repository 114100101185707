<template>
  <a-card :bodyStyle="{padding:0}">
    <template #title>
      <a-button @click="exportDetailExcel">导出明细</a-button>
    </template>
    <a-table :dataSource="dataSource" :row-key="record => record.orgId" :loading="loading" @change="tableChage" :pagination="false" bordered size="small" :scroll="{x: true}" :rowClassName="rowClass">
      <a-table-column width="200" title="部门" data-index="orgName" :filters="orgs" :onFilter="(value,record) => record.orgId === value || record.id === 'totalRow'" />
      <a-table-column width="50" title="年" data-index="year" />
      <a-table-column width="50" title="月" data-index="month" />
      <a-table-column width="100" title="应收金额" data-index="receivedAmt" />
      <a-table-column width="100" title="超期金额" data-index="overdueAmt" />
      <a-table-column width="100" title="金额逾期率" data-index="amtRate" />
      <template #footer></template>
    </a-table>
  </a-card>
</template>
<script>
import { accountAgeOrgList, exportAccountAgeDetail } from '@/api/trans/finance/accountAge'
import { reactive, toRefs } from 'vue'
import { watch } from '@vue/runtime-core'
import { message } from 'ant-design-vue'
export default {
  props: { query: { type: Object, default: () => { return { year: '', month: '' } } } },
  setup (props) {
    const state = reactive({
      loading: false,
      orgs: [],
      dataSource: []
    })

    watch(() => props.query, (query) => {
      accountAgeOrgList(query).then(res => {
        if (res.code === 10000) {
          console.log(res.data)
          state.dataSource = res.data
          state.orgs = res.data.map(val => { return { text: val.orgName, value: val.orgId } })
          state.dataSource.push(tableTotal(res.data))
        }
      })
    })

    const tableTotal = (data = []) => {
      const totalRow = {
        receivedAmt: 0,
        overdueAmt: 0
      }
      data.forEach(item => {
        for (const key in totalRow) {
          totalRow[key] += Number(item[key])
        }
      })
      totalRow.id = 'totalRow'
      totalRow.orgName = '合计'
      totalRow.amtRate = Number(totalRow.overdueAmt * 100 / totalRow.receivedAmt).toFixed(2) + '%'
      return totalRow
    }

    const tableChage = (pagination, filters) => {
      const filterData = state.dataSource.filter(item => filters.orgName.includes(item.orgId))
      if (filterData.length) {
        state.dataSource.splice(state.dataSource.length - 1, 1, tableTotal(filterData))
      } else {
        state.dataSource.splice(state.dataSource.length - 1, 1, tableTotal(state.dataSource.slice(0, -1)))
      }
    }

    const exportExcel = () => {
      exportAccountAgeDetail(props.query, '账龄报表').then(res => {
        if (res === 10000) {
          message('导出成功！')
        }
      })
    }

    const exportDetailExcel = () => {
      exportAccountAgeDetail(props.query, '账龄报表').then(res => {
        if (res === 10000) {
          message('导出成功！')
        }
      })
    }

    const rowClass = (record, index) => {
      let rowClassName = ''
      if (index % 2 === 1) {
        rowClassName = 'table-striped'
      }
      if (record.status && !record.status.value) {
        rowClassName += ' not-archive'
      }
      console.log(record)
      return rowClassName
    }
    return {
      ...toRefs(state),
      rowClass,
      tableChage,
      exportExcel,
      exportDetailExcel
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
